<template>
  <section class="tables painel_controle">
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">
              Controle de Chamadas -
              <span class="text-primary">{{ callDay }}</span>
            </h4>

            <div class="set_calendar">

              


              <form class="search">
                <input
                  v-model="searchText"
                  type="search"
                  placeholder="Buscar Operador"
                  class="form-control "
                />
              </form>

              
              <div class="set_calendar_container">

                <div>
                <b-button variant="primary" class="h-100" v-b-modal.modal-xl
                  >Pesquisa Avançada</b-button
                >
                </div>



                <div>
                  <!-- <label for="dateinit" class="text-muted mb-0"
                    >Data início<</label
                  > -->
                  <input
                    id="dateStart"
                    placeholder="Data início<"
                    class="form-control"
                    type="date"
                    v-model="dateStart"
                  />
                </div>

                <!-- <div>
                  <label for="dateEnd" class="text-muted mb-0"
                    >Data Final</label
                  >

                  <input
                    id="dateEnd"
                    placeholder="Data Final"
                    class="form-control"
                    type="date"
                    v-model="dateEnd"
                  />
                </div> -->

                <div class="d-flex flex-column justify-content-end">
                  <label for="dateEnd" class="text-muted mb-0"> </label>

                  <button
                    type="button"
                    class="btn btn-primary h-100"
                    @click="searchCalls"
                  >
                    Atualizar
                  </button>

                  <!-- <button class="btn btn-primary" > 123 </button> -->
                </div>
              </div>
            </div>

            <b-table
              :items="filteredItems.length > 0 ? filteredItems : items"
              id="table-list"
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="loadingTable"
            >
              <template #table-busy>
                <div class="text-center text-white my-2 loadding-white-mode">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong> Carregando...</strong>
                </div>
              </template>

              <template v-slot:cell(nome)="data">
                <span class="text-muted text-center name__strong">{{
                  data.value
                }}</span>
              </template>

              <template v-slot:cell(efetuadas)="data">
                <span :class="data.value > 0 ? 'text-primary' : ''">{{
                  data.value
                }}</span>
              </template>

              <template v-slot:cell(completas)="data">
                <span :class="data.value > 0 ? 'text-success' : ''">{{
                  data.value
                }}</span>
              </template>

              <template v-slot:cell(incompletas_|_nao_atende)="data">
                <span :class="data.value > 0 ? 'text-warning' : ''">{{
                  data.value
                }}</span>
              </template>
              <template v-slot:cell(Ocupado)="data">
                <span :class="data.value > 0 ? 'text-info' : ''">{{
                  data.value
                }}</span>
              </template>

              <template v-slot:cell(falha)="data">
                <span :class="data.value > 0 ? 'text-danger' : ''">{{
                  data.value
                }}</span>
              </template>

              <template v-slot:cell(tempo_falado)="data">
                <span>{{ data.value | formatForMinutes }}</span>
              </template>

              <template v-slot:cell(recebidas)>
                <span>?</span>
              </template>
              <template v-slot:cell(action)="data">
                <!-- <span v-html="data.value"></span> -->
                <b-button
                  variant="primary"
                  :to="{
                    name: 'PainelChamadasEfetuadas',
                    params: { id: data.item.userId },
                  }"
                  >Detalhes</b-button
                >
              </template>
            </b-table>

            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="table-list"
              align="right"
            >
            </b-pagination>
          </div>
        </div>
      </div>
    </div>


    <b-modal
      id="modal-xl"
      size="lg"
      :title=" 'Pesquisa Avançada'"
      scrollable
      no-close-on-backdrop
      hide-footer
      :content-class="
        !this.$store.state.showWhiteMode
          ? 'modallEditarStatus'
          : 'modallEditarStatusWhite'
      "
    >
      <ModalAdminFiltrosChamadas  />
    </b-modal>



  </section>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";

import TwilioService from "@/services/twilio.service";
import UserService from "@/services/user.service";
import ModalAdminFiltrosChamadas from "./components/ModalAdminFiltrosChamadas.vue";


// import SocketioService from "../../services/socketio.service";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components:{
    ModalAdminFiltrosChamadas
  },
  data: function () {
    return {
      // sortBy: 'name',
      sortBy: "efetuadas",
      perPage: 10,
      currentPage: 1,
      sortDesc: true,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      loadingTable: false,
      fields: [
        { key: "nome", sortable: true },
        { key: "efetuadas", sortable: true },
        { key: "completas", sortable: true },
        { key: "incompletas_|_nao_atende", sortable: true },
        { key: "Ocupado", sortable: true },
        { key: "falha", sortable: true },
        { key: "tempo_falado", sortable: true },
        { key: "recebidas", sortable: true },
        // { key: 'customer', sortable: true },
        // { key: 'shipTo', sortable: true },
        // { key: 'basePrice', sortable: true },
        // { key: 'purchasedPrice', sortable: true },
        // { key: "status", sortable: true },
        { key: "action", sortable: true },
        // { key: "userId",  sortable: true  },
      ],
      items: [],

      dateStart: new Date(),
      allUsers: [],
      callDay: "",
      today: undefined,

      searchText: "",
      filteredItems: [],
    };
  },

  methods: {
    async getAllUsers() {
      this.loadingTable = true;

      try {
        const params = {
          perPage: 50,
          orderBy: "nome",
        };

        const res = await UserService.getAll(params);
        const allUsers = res.data.data;
        this.allUsers = allUsers;
        this.getHistoryCalls(allUsers);
        this.loadingTable = true;
      } catch (error) {
        console.error(error);
        this.loadingTable = false;
      }
    },

    async getHistoryCalls(allUsers) {
      this.loadingTable = true;
      this.items = [];
      this.callDay =
        this.dateStart === this.today
          ? "Hoje"
          : this.dateStart.split("-").reverse().join("-");

      allUsers.forEach((user) => {
        // console.log(user);

        TwilioService.getCallsFromAgents(user.email, this.dateStart)
          .then((res) => {
            // console.log(res.data);
            const calls = res.data.calls;

            this.items.push({
              nome: user.nome,
              efetuadas: calls.length,
              // status: '<label class="badge badge-info">On hold</label>',
              // status:
              //   user.nome === "Joel"
              //     ? '<label class="badge badge-success">Online</label>'
              //     : '<label class="badge badge-danger">Off</label> ',
              action:
                '<button class="btn btn-outline-primary px-4 py-1" ><i class="mdi mdi-eye-outline text-primary mr-2"></i>View</button>',
              userId: user.id,
              tempo_falado: this.getSpokenTime(calls),
              completas: this.getCompletesCall(calls),
              "incompletas_|_nao_atende": this.getIncompletesCalls(calls),
              Ocupado: this.getBusyCall(calls),
              falha: this.getFailedCall(calls),
            });

            this.loadingTable = false;

            // console.log(allUsers.length);
          })
          .catch((error) => {
            console.error(error);
            this.loadingTable = false;
          });
      });
    },

    formateDate(date) {
      if (date) {
        const dateString = new Date(
          date.getTime() - date.getTimezoneOffset() * 60000
        )
          .toISOString()
          .split("T")[0];

        return dateString;
      }
    },

    searchCalls() {
      this.getHistoryCalls(this.allUsers);
    },

    getSpokenTime(calls) {
      let time = 0;
      calls.forEach((item) => {
        time = time + Number(item.duration);
      });
      return time;
    },
    getCompletesCall(calls) {
      const newArray = calls.filter((item) => item.status == "completed");
      return newArray.length;
    },
    getIncompletesCalls(calls) {
      const newArray = calls.filter((item) => item.status == "no-answer");
      return newArray.length;
    },
    getFailedCall(calls) {
      const newArray = calls.filter((item) => item.status == "failed");
      return newArray.length;
    },

    getBusyCall(calls) {
      const newArray = calls.filter((item) => item.status == "busy");
      return newArray.length;
    },

    searchInArray(searchQuery, array, objectKey = null) {
      return array.filter((d) => {
        let data = objectKey ? d[objectKey] : d; //Incase If It's Array Of Objects.
        let dataWords =
          typeof data == "string" &&
          data
            ?.split(" ")
            ?.map((b) => b && b.toLowerCase().trim())
            .filter((b) => b);
        let searchWords =
          typeof searchQuery == "string" &&
          searchQuery
            ?.split(" ")
            .map((b) => b && b.toLowerCase().trim())
            .filter((b) => b);

        let matchingWords = searchWords.filter((word) =>
          dataWords.includes(word)
        );

        return matchingWords.length;
      });
    },
  },

  watch: {
    dateStart() {
      // console.log(this.dateStart);
      this.filteredItems = [];
      this.searchText = "";
      this.getHistoryCalls(this.allUsers);
    },
    dateEnd() {
      // console.log(this.dateEnd);
    },

    searchText() {
      this.filteredItems = this.searchInArray(
        this.searchText,
        this.items,
        "nome"
      );
    },
  },

  mounted() {
    this.dateStart = this.formateDate(new Date());

    this.getAllUsers();

    this.today = this.formateDate(new Date());

    // console.log(this.today);
  },

  computed: {
    rows() {
      return this.filteredItems.length || this.items.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.set_calendar {
  width: 100%;
  display: flex;

  justify-content: space-between;
  align-items: center;

  z-index: 4;

  .set_calendar_container {
    /* max-width: 350px; */
    display: flex;
    position: relative;

    div {
      margin: 0 10px;
    }
  }

  .calendarStart {
    position: absolute;
    top: 50px;
    left: -36px;
  }
}
</style>

<style lang="scss">
.painel_controle {
  table {
    text-align: center;
  }

  td[aria-colindex="1"],
  th[aria-colindex="1"] {
    font-weight: 600;
    text-align: left;
  }
}
</style>
